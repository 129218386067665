import { IconProps } from "./IconProps";

const CheckIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg
      height={height}
      width={width}
      className={className}
      fill="currentColor"
      viewBox="0 0 20 20"
      {...props}
    >
      <path d="M6.76047 14.7457C6.16901 14.0812 5.69122 13.4312 5.22969 12.8017C4.80796 12.2293 4.44229 11.6177 4.13772 10.9752C3.9471 10.5854 3.86272 10.1907 4.27563 9.93693C5.45897 9.20971 5.70147 9.90409 6.34466 10.7098C6.72591 11.1872 7.2881 11.9744 7.64035 12.4718C7.97682 12.9468 8.38701 12.0895 8.55076 11.8476C9.13276 10.9879 10.6454 8.88315 11.2705 8.10515C11.8639 7.36659 13.7794 5.29753 14.1365 4.96843C14.4404 4.68822 15.0821 4.07359 15.5137 4.41265C15.9671 4.7689 16.1887 5.44609 15.8699 5.8864C15.3148 6.65297 14.4428 7.38218 13.8395 8.11209C12.6182 9.58959 11.4619 11.1822 10.352 12.768C9.97482 13.3068 9.44529 14.2094 9.11654 14.7938C8.50157 15.8871 7.96141 16.0951 6.76047 14.7457Z" />
    </svg>
  );
};

export default CheckIcon;
