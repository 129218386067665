import { IconProps } from "./IconProps";

const RocketIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg
      height={height}
      width={width}
      className={className}
      fill="currentColor"
      viewBox="0 0 20 20"
      {...props}
    >
      <g clip-path="url(#clip0_22_490)">
        <path d="M6.39385 10.6852C6.24111 10.8375 6.24111 11.0849 6.39385 11.2376C6.54659 11.3899 6.79378 11.3899 6.94622 11.2376C7.09896 11.0849 7.09896 10.8375 6.94622 10.6852C6.79378 10.5325 6.54659 10.5325 6.39385 10.6852Z" />
        <path d="M8.10911 17.7912L9.09437 16.1488L9.70731 16.7617C9.73432 16.7887 9.76423 16.8105 9.79597 16.8279L10.1497 19.3029C10.1954 19.623 10.5864 19.7498 10.8125 19.5239L14.127 16.2093C14.1897 16.1466 14.2291 16.0645 14.239 15.9763L14.6383 12.3837L16.8473 10.1742C17.8173 9.2042 18.5897 7.84785 19.1433 6.14283C19.9331 3.70982 19.9975 1.44069 19.9998 1.34517C20.0024 1.23836 19.9612 1.13506 19.8855 1.05938C19.81 0.983693 19.7073 0.942189 19.5997 0.945089C19.5044 0.94753 17.2352 1.01177 14.8021 1.80156C13.097 2.35515 11.7407 3.1277 10.7707 4.0977L8.56184 6.30656L4.96855 6.70588C4.88036 6.71565 4.79826 6.75517 4.73555 6.81788L1.42104 10.1324C1.31637 10.2371 1.2799 10.3918 1.32674 10.532C1.37344 10.6724 1.49551 10.7743 1.64199 10.7952L4.11696 11.1489C4.16503 11.2367 4.16915 11.2158 4.79613 11.8505L3.15368 12.8356C3.05053 12.8976 2.98171 13.0035 2.96706 13.123C2.95226 13.2423 2.99346 13.3619 3.07845 13.4469L7.498 17.8664C7.67668 18.0451 7.97773 18.0102 8.10911 17.7912ZM10.8125 18.419L10.5362 16.4854L13.7539 13.2679L13.4776 15.7539L10.8125 18.419ZM18.5858 5.28392L15.661 2.35912C17.153 1.94484 18.527 1.79958 19.1946 1.75029C19.1455 2.41802 19 3.79207 18.5858 5.28392ZM11.3231 4.65007C12.1626 3.81053 13.338 3.12862 14.8176 2.62065L18.3244 6.12727C17.8163 7.60691 17.1344 8.78229 16.2948 9.62183L11.0887 14.828L8.05052 11.7898C7.89793 11.6374 7.65058 11.6374 7.498 11.7898C7.34541 11.9424 7.34541 12.1897 7.498 12.3423L10.5362 15.3805L9.98365 15.9332L5.01189 10.9614L9.01716 6.95628L11.3231 4.65007ZM5.19103 7.46729L7.67744 7.19096L4.45952 10.4087L2.52593 10.1324L5.19103 7.46729ZM5.36559 12.42L8.52491 15.5793L7.69667 16.9602L3.98482 13.2484L5.36559 12.42Z" />
        <path d="M0.666808 20.8305L4.73556 16.7618C4.88814 16.6093 4.88814 16.362 4.73556 16.2094C4.58297 16.0568 4.33578 16.0568 4.18319 16.2094L0.114441 20.2782C-0.0381469 20.4307 -0.0381469 20.6781 0.114441 20.8307C0.267028 20.9831 0.51422 20.9831 0.666808 20.8305Z" />
        <path d="M5.84033 17.3141C5.68774 17.1616 5.44039 17.1616 5.28781 17.3141L2.87616 19.7258C2.72357 19.8784 2.72357 20.1257 2.87616 20.2783C3.02875 20.4307 3.27594 20.4307 3.42853 20.2783L5.84033 17.8665C5.99276 17.7139 5.99276 17.4666 5.84033 17.3141Z" />
        <path d="M3.07882 15.1043L0.667175 17.516C0.514587 17.6685 0.514587 17.9159 0.667175 18.0685C0.819763 18.2209 1.06695 18.2209 1.21954 18.0685L3.63134 15.6567C3.78378 15.5042 3.78378 15.2569 3.63134 15.1043C3.47875 14.9517 3.23141 14.9517 3.07882 15.1043Z" />
        <path d="M14.361 9.89818C15.2746 8.98448 15.2746 7.49767 14.3609 6.58352C13.4469 5.66998 11.9601 5.67013 11.0464 6.58367C10.1308 7.49935 10.1299 8.9822 11.0464 9.89818C11.962 10.8142 13.4447 10.8143 14.361 9.89818ZM11.5989 7.13604C12.2079 6.52706 13.1992 6.52706 13.8085 7.13604C14.4175 7.74532 14.4176 8.73668 13.8085 9.34566C13.1978 9.95631 12.2095 9.95677 11.5989 9.34566C10.9882 8.73531 10.9879 7.74685 11.5989 7.13604Z" />
      </g>
      <defs>
        <clipPath id="clip0_22_490">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.944946)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RocketIcon;
