/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateGithubTokenInput = {
  id?: string | null;
  token: string;
  inUse?: boolean | null;
  deleted?: boolean | null;
  name: string;
  tokenStatus?: TokenStatus | null;
  tokenPath?: string | null;
  _version?: number | null;
};

export enum TokenStatus {
  ACTIVE = "ACTIVE",
  TO_BE_DELETED = "TO_BE_DELETED",
  DELETED = "DELETED",
}

export type ModelGithubTokenConditionInput = {
  token?: ModelStringInput | null;
  inUse?: ModelBooleanInput | null;
  deleted?: ModelBooleanInput | null;
  name?: ModelStringInput | null;
  tokenStatus?: ModelTokenStatusInput | null;
  tokenPath?: ModelStringInput | null;
  and?: Array<ModelGithubTokenConditionInput | null> | null;
  or?: Array<ModelGithubTokenConditionInput | null> | null;
  not?: ModelGithubTokenConditionInput | null;
  _deleted?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelTokenStatusInput = {
  eq?: TokenStatus | null;
  ne?: TokenStatus | null;
};

export type GithubToken = {
  __typename: "GithubToken";
  id: string;
  token: string;
  inUse?: boolean | null;
  deleted?: boolean | null;
  name: string;
  tokenStatus?: TokenStatus | null;
  tokenPath?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateGithubTokenInput = {
  id: string;
  token?: string | null;
  inUse?: boolean | null;
  deleted?: boolean | null;
  name?: string | null;
  tokenStatus?: TokenStatus | null;
  tokenPath?: string | null;
  _version?: number | null;
};

export type DeleteGithubTokenInput = {
  id: string;
  _version?: number | null;
};

export type CreateClientInput = {
  id?: string | null;
  name: string;
  githubToken: string;
  scaleOpsToken?: string | null;
  isScaleOpsDev?: boolean | null;
  useScaleOpsToken?: boolean | null;
  url: string;
  metricsVerbosity?: MetricsVisability | null;
  isTrialExpired?: boolean | null;
  isDeleted?: boolean | null;
  status?: Status | null;
  firstInstallDate?: string | null;
  _version?: number | null;
};

export enum MetricsVisability {
  KEEP = "KEEP",
  NONE = "NONE",
  MINIMAL = "MINIMAL",
  POC = "POC",
  ALL = "ALL",
}

export enum Status {
  FreeTrial = "FreeTrial",
  Premium = "Premium",
  Pause = "Pause",
  Terminated = "Terminated",
}

export type ModelClientConditionInput = {
  name?: ModelStringInput | null;
  githubToken?: ModelStringInput | null;
  scaleOpsToken?: ModelStringInput | null;
  isScaleOpsDev?: ModelBooleanInput | null;
  useScaleOpsToken?: ModelBooleanInput | null;
  url?: ModelStringInput | null;
  metricsVerbosity?: ModelMetricsVisabilityInput | null;
  isTrialExpired?: ModelBooleanInput | null;
  isDeleted?: ModelBooleanInput | null;
  status?: ModelStatusInput | null;
  firstInstallDate?: ModelStringInput | null;
  and?: Array<ModelClientConditionInput | null> | null;
  or?: Array<ModelClientConditionInput | null> | null;
  not?: ModelClientConditionInput | null;
  _deleted?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type ModelMetricsVisabilityInput = {
  eq?: MetricsVisability | null;
  ne?: MetricsVisability | null;
};

export type ModelStatusInput = {
  eq?: Status | null;
  ne?: Status | null;
};

export type Client = {
  __typename: "Client";
  id: string;
  name: string;
  githubToken: string;
  scaleOpsToken?: string | null;
  isScaleOpsDev?: boolean | null;
  useScaleOpsToken?: boolean | null;
  url: string;
  metricsVerbosity?: MetricsVisability | null;
  isTrialExpired?: boolean | null;
  isDeleted?: boolean | null;
  status?: Status | null;
  firstInstallDate?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateClientInput = {
  id: string;
  name?: string | null;
  githubToken?: string | null;
  scaleOpsToken?: string | null;
  isScaleOpsDev?: boolean | null;
  useScaleOpsToken?: boolean | null;
  url?: string | null;
  metricsVerbosity?: MetricsVisability | null;
  isTrialExpired?: boolean | null;
  isDeleted?: boolean | null;
  status?: Status | null;
  firstInstallDate?: string | null;
  _version?: number | null;
};

export type DeleteClientInput = {
  id: string;
  _version?: number | null;
};

export type ClientByHashNameResponse = {
  __typename: "ClientByHashNameResponse";
  id: string;
  name: string;
  githubToken: string;
  scaleOpsToken?: string | null;
  isScaleOpsDev?: boolean | null;
  useScaleOpsToken?: boolean | null;
  url: string;
  metricsVerbosity?: MetricsVisability | null;
  isTrialExpired?: boolean | null;
  isDeleted?: boolean | null;
  status?: Status | null;
  firstInstallDate?: string | null;
  freeTrialStartDate?: string | null;
  createdAt: string;
  updatedAt: string;
  _version?: number | null;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelGithubTokenFilterInput = {
  id?: ModelIDInput | null;
  token?: ModelStringInput | null;
  inUse?: ModelBooleanInput | null;
  deleted?: ModelBooleanInput | null;
  name?: ModelStringInput | null;
  tokenStatus?: ModelTokenStatusInput | null;
  tokenPath?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelGithubTokenFilterInput | null> | null;
  or?: Array<ModelGithubTokenFilterInput | null> | null;
  not?: ModelGithubTokenFilterInput | null;
  _deleted?: ModelBooleanInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelGithubTokenConnection = {
  __typename: "ModelGithubTokenConnection";
  items: Array<GithubToken | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelClientFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  githubToken?: ModelStringInput | null;
  scaleOpsToken?: ModelStringInput | null;
  isScaleOpsDev?: ModelBooleanInput | null;
  useScaleOpsToken?: ModelBooleanInput | null;
  url?: ModelStringInput | null;
  metricsVerbosity?: ModelMetricsVisabilityInput | null;
  isTrialExpired?: ModelBooleanInput | null;
  isDeleted?: ModelBooleanInput | null;
  status?: ModelStatusInput | null;
  firstInstallDate?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelClientFilterInput | null> | null;
  or?: Array<ModelClientFilterInput | null> | null;
  not?: ModelClientFilterInput | null;
  _deleted?: ModelBooleanInput | null;
};

export type ModelClientConnection = {
  __typename: "ModelClientConnection";
  items: Array<Client | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export type ModelSubscriptionGithubTokenFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  token?: ModelSubscriptionStringInput | null;
  inUse?: ModelSubscriptionBooleanInput | null;
  deleted?: ModelSubscriptionBooleanInput | null;
  name?: ModelSubscriptionStringInput | null;
  tokenStatus?: ModelSubscriptionStringInput | null;
  tokenPath?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionGithubTokenFilterInput | null> | null;
  or?: Array<ModelSubscriptionGithubTokenFilterInput | null> | null;
  _deleted?: ModelBooleanInput | null;
};

export type ModelSubscriptionIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
};

export type ModelSubscriptionClientFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  githubToken?: ModelSubscriptionStringInput | null;
  scaleOpsToken?: ModelSubscriptionStringInput | null;
  isScaleOpsDev?: ModelSubscriptionBooleanInput | null;
  useScaleOpsToken?: ModelSubscriptionBooleanInput | null;
  url?: ModelSubscriptionStringInput | null;
  metricsVerbosity?: ModelSubscriptionStringInput | null;
  isTrialExpired?: ModelSubscriptionBooleanInput | null;
  isDeleted?: ModelSubscriptionBooleanInput | null;
  status?: ModelSubscriptionStringInput | null;
  firstInstallDate?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionClientFilterInput | null> | null;
  or?: Array<ModelSubscriptionClientFilterInput | null> | null;
  _deleted?: ModelBooleanInput | null;
};

export type CreateGithubTokenMutationVariables = {
  input: CreateGithubTokenInput;
  condition?: ModelGithubTokenConditionInput | null;
};

export type CreateGithubTokenMutation = {
  createGithubToken?: {
    __typename: "GithubToken";
    id: string;
    token: string;
    inUse?: boolean | null;
    deleted?: boolean | null;
    name: string;
    tokenStatus?: TokenStatus | null;
    tokenPath?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
};

export type UpdateGithubTokenMutationVariables = {
  input: UpdateGithubTokenInput;
  condition?: ModelGithubTokenConditionInput | null;
};

export type UpdateGithubTokenMutation = {
  updateGithubToken?: {
    __typename: "GithubToken";
    id: string;
    token: string;
    inUse?: boolean | null;
    deleted?: boolean | null;
    name: string;
    tokenStatus?: TokenStatus | null;
    tokenPath?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
};

export type DeleteGithubTokenMutationVariables = {
  input: DeleteGithubTokenInput;
  condition?: ModelGithubTokenConditionInput | null;
};

export type DeleteGithubTokenMutation = {
  deleteGithubToken?: {
    __typename: "GithubToken";
    id: string;
    token: string;
    inUse?: boolean | null;
    deleted?: boolean | null;
    name: string;
    tokenStatus?: TokenStatus | null;
    tokenPath?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
};

export type CreateClientMutationVariables = {
  input: CreateClientInput;
  condition?: ModelClientConditionInput | null;
};

export type CreateClientMutation = {
  createClient?: {
    __typename: "Client";
    id: string;
    name: string;
    githubToken: string;
    scaleOpsToken?: string | null;
    isScaleOpsDev?: boolean | null;
    useScaleOpsToken?: boolean | null;
    url: string;
    metricsVerbosity?: MetricsVisability | null;
    isTrialExpired?: boolean | null;
    isDeleted?: boolean | null;
    status?: Status | null;
    firstInstallDate?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
};

export type UpdateClientMutationVariables = {
  input: UpdateClientInput;
  condition?: ModelClientConditionInput | null;
};

export type UpdateClientMutation = {
  updateClient?: {
    __typename: "Client";
    id: string;
    name: string;
    githubToken: string;
    scaleOpsToken?: string | null;
    isScaleOpsDev?: boolean | null;
    useScaleOpsToken?: boolean | null;
    url: string;
    metricsVerbosity?: MetricsVisability | null;
    isTrialExpired?: boolean | null;
    isDeleted?: boolean | null;
    status?: Status | null;
    firstInstallDate?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
};

export type DeleteClientMutationVariables = {
  input: DeleteClientInput;
  condition?: ModelClientConditionInput | null;
};

export type DeleteClientMutation = {
  deleteClient?: {
    __typename: "Client";
    id: string;
    name: string;
    githubToken: string;
    scaleOpsToken?: string | null;
    isScaleOpsDev?: boolean | null;
    useScaleOpsToken?: boolean | null;
    url: string;
    metricsVerbosity?: MetricsVisability | null;
    isTrialExpired?: boolean | null;
    isDeleted?: boolean | null;
    status?: Status | null;
    firstInstallDate?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
};

export type GetClientByHashNameQueryVariables = {
  name?: string | null;
  hash?: string | null;
  token?: string | null;
};

export type GetClientByHashNameQuery = {
  getClientByHashName?: {
    __typename: "ClientByHashNameResponse";
    id: string;
    name: string;
    githubToken: string;
    scaleOpsToken?: string | null;
    isScaleOpsDev?: boolean | null;
    useScaleOpsToken?: boolean | null;
    url: string;
    metricsVerbosity?: MetricsVisability | null;
    isTrialExpired?: boolean | null;
    isDeleted?: boolean | null;
    status?: Status | null;
    firstInstallDate?: string | null;
    freeTrialStartDate?: string | null;
    createdAt: string;
    updatedAt: string;
    _version?: number | null;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
};

export type GetGithubTokenQueryVariables = {
  id: string;
};

export type GetGithubTokenQuery = {
  getGithubToken?: {
    __typename: "GithubToken";
    id: string;
    token: string;
    inUse?: boolean | null;
    deleted?: boolean | null;
    name: string;
    tokenStatus?: TokenStatus | null;
    tokenPath?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
};

export type ListGithubTokensQueryVariables = {
  filter?: ModelGithubTokenFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListGithubTokensQuery = {
  listGithubTokens?: {
    __typename: "ModelGithubTokenConnection";
    items: Array<{
      __typename: "GithubToken";
      id: string;
      token: string;
      inUse?: boolean | null;
      deleted?: boolean | null;
      name: string;
      tokenStatus?: TokenStatus | null;
      tokenPath?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
};

export type SyncGithubTokensQueryVariables = {
  filter?: ModelGithubTokenFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
  lastSync?: number | null;
};

export type SyncGithubTokensQuery = {
  syncGithubTokens?: {
    __typename: "ModelGithubTokenConnection";
    items: Array<{
      __typename: "GithubToken";
      id: string;
      token: string;
      inUse?: boolean | null;
      deleted?: boolean | null;
      name: string;
      tokenStatus?: TokenStatus | null;
      tokenPath?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
};

export type GetClientQueryVariables = {
  id: string;
};

export type GetClientQuery = {
  getClient?: {
    __typename: "Client";
    id: string;
    name: string;
    githubToken: string;
    scaleOpsToken?: string | null;
    isScaleOpsDev?: boolean | null;
    useScaleOpsToken?: boolean | null;
    url: string;
    metricsVerbosity?: MetricsVisability | null;
    isTrialExpired?: boolean | null;
    isDeleted?: boolean | null;
    status?: Status | null;
    firstInstallDate?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
};

export type ListClientsQueryVariables = {
  filter?: ModelClientFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListClientsQuery = {
  listClients?: {
    __typename: "ModelClientConnection";
    items: Array<{
      __typename: "Client";
      id: string;
      name: string;
      githubToken: string;
      scaleOpsToken?: string | null;
      isScaleOpsDev?: boolean | null;
      useScaleOpsToken?: boolean | null;
      url: string;
      metricsVerbosity?: MetricsVisability | null;
      isTrialExpired?: boolean | null;
      isDeleted?: boolean | null;
      status?: Status | null;
      firstInstallDate?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
};

export type SyncClientsQueryVariables = {
  filter?: ModelClientFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
  lastSync?: number | null;
};

export type SyncClientsQuery = {
  syncClients?: {
    __typename: "ModelClientConnection";
    items: Array<{
      __typename: "Client";
      id: string;
      name: string;
      githubToken: string;
      scaleOpsToken?: string | null;
      isScaleOpsDev?: boolean | null;
      useScaleOpsToken?: boolean | null;
      url: string;
      metricsVerbosity?: MetricsVisability | null;
      isTrialExpired?: boolean | null;
      isDeleted?: boolean | null;
      status?: Status | null;
      firstInstallDate?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
};

export type ByNameQueryVariables = {
  name: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelClientFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ByNameQuery = {
  byName?: {
    __typename: "ModelClientConnection";
    items: Array<{
      __typename: "Client";
      id: string;
      name: string;
      githubToken: string;
      scaleOpsToken?: string | null;
      isScaleOpsDev?: boolean | null;
      useScaleOpsToken?: boolean | null;
      url: string;
      metricsVerbosity?: MetricsVisability | null;
      isTrialExpired?: boolean | null;
      isDeleted?: boolean | null;
      status?: Status | null;
      firstInstallDate?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
};

export type ByGithubTokenQueryVariables = {
  githubToken: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelClientFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ByGithubTokenQuery = {
  byGithubToken?: {
    __typename: "ModelClientConnection";
    items: Array<{
      __typename: "Client";
      id: string;
      name: string;
      githubToken: string;
      scaleOpsToken?: string | null;
      isScaleOpsDev?: boolean | null;
      useScaleOpsToken?: boolean | null;
      url: string;
      metricsVerbosity?: MetricsVisability | null;
      isTrialExpired?: boolean | null;
      isDeleted?: boolean | null;
      status?: Status | null;
      firstInstallDate?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
};

export type ByScaleOpsTokenQueryVariables = {
  scaleOpsToken: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelClientFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ByScaleOpsTokenQuery = {
  byScaleOpsToken?: {
    __typename: "ModelClientConnection";
    items: Array<{
      __typename: "Client";
      id: string;
      name: string;
      githubToken: string;
      scaleOpsToken?: string | null;
      isScaleOpsDev?: boolean | null;
      useScaleOpsToken?: boolean | null;
      url: string;
      metricsVerbosity?: MetricsVisability | null;
      isTrialExpired?: boolean | null;
      isDeleted?: boolean | null;
      status?: Status | null;
      firstInstallDate?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
};

export type ByUrlQueryVariables = {
  url: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelClientFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ByUrlQuery = {
  byUrl?: {
    __typename: "ModelClientConnection";
    items: Array<{
      __typename: "Client";
      id: string;
      name: string;
      githubToken: string;
      scaleOpsToken?: string | null;
      isScaleOpsDev?: boolean | null;
      useScaleOpsToken?: boolean | null;
      url: string;
      metricsVerbosity?: MetricsVisability | null;
      isTrialExpired?: boolean | null;
      isDeleted?: boolean | null;
      status?: Status | null;
      firstInstallDate?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
};

export type OnCreateGithubTokenSubscriptionVariables = {
  filter?: ModelSubscriptionGithubTokenFilterInput | null;
};

export type OnCreateGithubTokenSubscription = {
  onCreateGithubToken?: {
    __typename: "GithubToken";
    id: string;
    token: string;
    inUse?: boolean | null;
    deleted?: boolean | null;
    name: string;
    tokenStatus?: TokenStatus | null;
    tokenPath?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
};

export type OnUpdateGithubTokenSubscriptionVariables = {
  filter?: ModelSubscriptionGithubTokenFilterInput | null;
};

export type OnUpdateGithubTokenSubscription = {
  onUpdateGithubToken?: {
    __typename: "GithubToken";
    id: string;
    token: string;
    inUse?: boolean | null;
    deleted?: boolean | null;
    name: string;
    tokenStatus?: TokenStatus | null;
    tokenPath?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
};

export type OnDeleteGithubTokenSubscriptionVariables = {
  filter?: ModelSubscriptionGithubTokenFilterInput | null;
};

export type OnDeleteGithubTokenSubscription = {
  onDeleteGithubToken?: {
    __typename: "GithubToken";
    id: string;
    token: string;
    inUse?: boolean | null;
    deleted?: boolean | null;
    name: string;
    tokenStatus?: TokenStatus | null;
    tokenPath?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
};

export type OnCreateClientSubscriptionVariables = {
  filter?: ModelSubscriptionClientFilterInput | null;
};

export type OnCreateClientSubscription = {
  onCreateClient?: {
    __typename: "Client";
    id: string;
    name: string;
    githubToken: string;
    scaleOpsToken?: string | null;
    isScaleOpsDev?: boolean | null;
    useScaleOpsToken?: boolean | null;
    url: string;
    metricsVerbosity?: MetricsVisability | null;
    isTrialExpired?: boolean | null;
    isDeleted?: boolean | null;
    status?: Status | null;
    firstInstallDate?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
};

export type OnUpdateClientSubscriptionVariables = {
  filter?: ModelSubscriptionClientFilterInput | null;
};

export type OnUpdateClientSubscription = {
  onUpdateClient?: {
    __typename: "Client";
    id: string;
    name: string;
    githubToken: string;
    scaleOpsToken?: string | null;
    isScaleOpsDev?: boolean | null;
    useScaleOpsToken?: boolean | null;
    url: string;
    metricsVerbosity?: MetricsVisability | null;
    isTrialExpired?: boolean | null;
    isDeleted?: boolean | null;
    status?: Status | null;
    firstInstallDate?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
};

export type OnDeleteClientSubscriptionVariables = {
  filter?: ModelSubscriptionClientFilterInput | null;
};

export type OnDeleteClientSubscription = {
  onDeleteClient?: {
    __typename: "Client";
    id: string;
    name: string;
    githubToken: string;
    scaleOpsToken?: string | null;
    isScaleOpsDev?: boolean | null;
    useScaleOpsToken?: boolean | null;
    url: string;
    metricsVerbosity?: MetricsVisability | null;
    isTrialExpired?: boolean | null;
    isDeleted?: boolean | null;
    status?: Status | null;
    firstInstallDate?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
};
