import InstallGuide from "../components/InstallGuide";
import { HAS_NEW_LOGIN_PAGE } from "./CompanyPage";
import InstallAndUpgradeContainer from "./InstallAndUpgrade/InstallAndUpgradeContainer";

export default function Template() {
  return HAS_NEW_LOGIN_PAGE ? (
    <InstallAndUpgradeContainer
      token={"<YOUR_SCALEOPS_TOKEN>"}
      customerPrefix={"<Customer_Prefix>"}
    />
  ) : (
    <InstallGuide
      companyName={"<Company_Name>"}
      token={"<YOUR_SCALEOPS_TOKEN>"}
      customerPrefix={"<Customer_Prefix>"}
      hideHeader={true}
    />
  );
}
