import { DataStore } from "aws-amplify";
import { GithubToken } from "../models";
import React, { ReactElement, useEffect, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowParams,
  GridActionsCellItemProps,
  GridColumns,
} from "@mui/x-data-grid";

import DeleteIcon from "@mui/icons-material/Delete";
import { Button, LinearProgress } from "@mui/material";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import GithubTokenCreate from "./GithubTokenCreate";
import GithubTokenImport from "./GithubTokenImport";

export default function GithubTokensTable() {
  const [tokens, setTokens] = useState<GithubToken[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [createToken, setCreateToken] = useState<boolean>(false);

  useEffect(() => {
    const watcher = DataStore.observeQuery(GithubToken).subscribe(
      (snapshot) => {
        const { items, isSynced } = snapshot;
        console.log(
          `[Snapshot] item count: ${items.length}, isSynced: ${isSynced}`,
        );
        if (isSynced) {
          setTokens(items);
          setLoading(false);
        } else if (!loading) {
          setLoading(true);
        }
      },
    );
    return () => {
      watcher.unsubscribe();
    };
  });

  const columns: GridColumns = [
    {
      field: "token",
      headerName: "Github Token",
      flex: 1,
      type: "string",
      align: "left",
      headerAlign: "center",
      minWidth: 380,
      editable: false,
    },
    {
      field: "inUse",
      headerName: "In Use",
      flex: 1,
      type: "boolean",
      align: "center",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "deleted",
      headerName: "Deleted",
      flex: 1,
      type: "boolean",
      align: "center",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      type: "dateTime",
      align: "center",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 1,
      type: "dateTime",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "tokenPath",
      headerName: "Token Path",
      flex: 1,
      type: "string",
      align: "center",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "tokenStatus",
      headerName: "Token Status",
      flex: 1,
      type: "string",
      align: "center",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      getActions: (
        row: GridRowParams<GithubToken>,
      ): ReactElement<GridActionsCellItemProps>[] => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          onClick={() => {
            DataStore.save(
              GithubToken.copyOf(row.row, (draft) => {
                draft.deleted = true;
                return draft;
              }),
            )
              .then((result) => console.log(result))
              .catch((reason) => console.error(reason));
          }}
          label="Delete Token"
        />,
      ],
    },
  ];

  return (
    <div>
      <Button
        onClick={() => {
          setCreateToken(true);
        }}
      >
        Add Single Token
      </Button>
      <GithubTokenImport />
      <DataGrid
        components={{
          LoadingOverlay: LinearProgress,
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        loading={loading}
        disableSelectionOnClick={true}
        autoHeight={true}
        columns={columns}
        rows={tokens || []}
        initialState={{
          filter: {
            filterModel: {
              items: [
                {
                  id: 1,
                  columnField: "deleted",
                  operatorValue: "is",
                  value: true,
                },
              ],
            },
          },
        }}
      />
      <GithubTokenCreate
        open={createToken}
        onClose={() => {
          setCreateToken(false);
        }}
      />
    </div>
  );
}
