import { Typography } from "@mui/material";
import clsx from "clsx";
import ScaleopsIcon from "../../icons/ScaleopsIcon";
import { containerClassName, TabType } from "./utils";

const ICON_SIZE = "3.625rem";

interface Props {
  selectedTab: TabType;
  companyName?: string;
}

const TopPurpleBanner = ({ selectedTab, companyName }: Props) => {
  return (
    <div
      className={clsx(
        containerClassName,
        "bg-primary-lightPurpleBlue border-[0.1875rem] border-black",
      )}
    >
      <ScaleopsIcon width={ICON_SIZE} height={ICON_SIZE} />
      <div>
        {companyName && (
          <Typography variant="h5" fontWeight={700}>
            Welcome {companyName[0].toUpperCase() + companyName.slice(1)}!
          </Typography>
        )}
        <Typography variant={!!companyName ? "h6" : "h4"}>
          {selectedTab === TabType.INSTALL ? "Install" : "Upgrade"} ScaleOps in{" "}
          <b>less than 2 minutes</b>
        </Typography>
      </div>
    </div>
  );
};

export default TopPurpleBanner;
