import { GithubToken } from "../models";
import React, { useState } from "react";
import { DataStore } from "aws-amplify";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ModalProps,
} from "@mui/material";
import { Button, TextField } from "@aws-amplify/ui-react";

interface GithubTokenCreateProps {
  open: boolean;
  onClose?: ModalProps["onClose"];
}
export default function GithubToxkenCreate(props: GithubTokenCreateProps) {
  const [token, setToken] = useState<string>("");
  const [name, setName] = useState<string>("");

  const save = async () => {
    await DataStore.save(
      new GithubToken({
        token: token,
        name: name,
        deleted: false,
        inUse: false,
      }),
    );
    if (props.onClose) {
      props.onClose({}, "escapeKeyDown");
    }
  };

  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <DialogTitle>Add New Github Token</DialogTitle>
      <DialogContent>
        <TextField
          label={"Github Token"}
          onChange={(event) => {
            setToken(event.target.value);
          }}
        />
        <TextField
          label={"Github Token Name"}
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => save()}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}
