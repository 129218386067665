import { createTheme, ThemeOptions, ThemeProvider } from "@mui/material";
import React from "react";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import Admin from "./pages/Admin";
import CompanyPage from "./pages/CompanyPage";
import Template from "./pages/Template";
import reportWebVitals from "./reportWebVitals";

if (window.location.hostname === "install.demo.scaleops.sh") {
  window.location.assign(
    "https://install.scaleops.com" + window.location.pathname,
  );
}

export const themeOptions: ThemeOptions = createTheme({
  typography: {
    fontFamily:
      'Poppins, Roboto, sans-serif, Helvetica, "Bai Jamjuree", Arial, Helvetica, sans-serif',
  },
  palette: {
    mode: "light",
    primary: {
      main: "#18135B",
    },
    info: {
      main: "#5353E0",
    },
    secondary: {
      main: "#2E2599",
    },
    success: {
      main: "#10AE6E",
    },
    error: {
      main: "#F7426F",
    },
    warning: {
      main: "#ed8702",
    },
  },
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: "success",
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
        },
      },
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Outlet />
      </QueryParamProvider>
    ),
    children: [
      {
        index: true,
        path: "",
        element: <Admin />,
      },
      {
        path: "/admin",
        element: <Admin />,
      },
      {
        path: "/:companyName/:sha",
        element: <CompanyPage />,
      },
      {
        path: "/template",
        element: <Template />,
      },
    ],
  },
]);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={createTheme(themeOptions)}>
      <RouterProvider router={router}></RouterProvider>
      <ToastContainer />
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
