import { Client } from "../models";
import React from "react";
import { Dialog, DialogContent, DialogTitle, ModalProps } from "@mui/material";
import { ClientUpdateForm } from "../ui-components";

interface ClientEditProps {
  onClose?: ModalProps["onClose"];
  client?: Client;
}
export default function ClientEdit(props: ClientEditProps) {
  return (
    <Dialog onClose={props.onClose} open={props.client !== undefined}>
      <DialogTitle>{"Edit " + props.client?.name}</DialogTitle>
      <DialogContent>
        <ClientUpdateForm
          overrides={{
            githubToken: {
              label: "GitHub Token",
              isDisabled: true,
              display: "none",
            },
            scaleOpsToken: {
              label: "ScaleOps Token",
              isDisabled: true,
              display: "none",
            },
            isScaleOpsDev: {
              label: "Is ScaleOps Development",
              isDisabled: true,
              display: "none",
            },
            useScaleOpsToken: {
              label: "Use ScaleOps Token",
              isDisabled: true,
              display: "none",
            },
            isTrialExpired: {
              label: "Show trial expiration message",
              isDisabled: true,
              display: "none",
            },
            isDeleted: {
              isDisabled: true,
              display: "none",
            },
            url: {
              label: "URL",
              isDisabled: true,
              display: "none",
            },
            firstInstallDate: {
              isDisabled: true,
            },
            salesForceAccountUrl: {
              isDisabled: true,
            },
            accountExecutive: {
              display: "none",
            },
            solutionsEngineer: {
              display: "none",
            },
            accountExecutiveName: {
              isDisabled: true,
            },
            solutionsEngineerName: {
              isDisabled: true,
            },
            companySize: {
              isDisabled: true,
            },
            linkedInUrl: {
              isDisabled: true,
            },
            firstClusterRegion: {
              isDisabled: true,
            },
          }}
          onError={(fields, errorMessage) => {
            console.error(errorMessage);
            console.log(fields);
          }}
          onSubmit={(fields) => {
            if (fields.isTrialExpired) {
              if (
                !window.confirm(
                  "Trial expired popup will appear in customer's dashboard. Are you sure?",
                )
              ) {
                fields.isTrialExpired = false;
              }
            }
            return fields;
          }}
          onSuccess={() => {
            if (props.onClose !== undefined) {
              props.onClose({}, "escapeKeyDown");
            }
          }}
          client={props.client}
        />
      </DialogContent>
    </Dialog>
  );
}
