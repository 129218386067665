export default function Help() {
  return (
    <>
      <h1>Welcome to the Help page!</h1>
      <br />
      <h2>Colors Explanation</h2>
      <table className="align-middle w-full">
        <thead className="bg-gray-200 align-middle">
          <tr className="py-3 px-2 border border-black">
            <th className="py-3 px-2 border border-black">color</th>
            <th className="py-3 px-2 border border-black">
              install page status
            </th>
            <th className="py-3 px-2 border border-black">token status</th>
            <th className="py-3 px-2 border border-black">How to proceed</th>
          </tr>
        </thead>
        <tbody className="py-3 px-2 border border-black align-middle">
          <tr>
            <td className="py-3 px-2 border border-black">white</td>
            <td className="py-3 px-2 border border-black">Accessible</td>
            <td className="py-3 px-2 border border-black">
              Works
              <br />
              Client can use it to install ScaleOps
            </td>
            <td className="py-3 px-2 border border-black">
              To delete, press the delete icon in the table
            </td>
          </tr>
          <tr className="bg-amber-100">
            <td className="py-3 px-2 border border-black">yellow</td>
            <td className="py-3 px-2 border border-black">Inaccessible</td>
            <td className="py-3 px-2 border border-black">
              Works
              <br />
              Client can use it to install ScaleOps
            </td>
            <td className="py-3 px-2 border border-black">
              Contact R&D to make it red
            </td>
          </tr>
          <tr className="bg-red-100">
            <td className="py-3 px-2 border border-black">red</td>
            <td className="py-3 px-2 border border-black">Inaccessible</td>
            <td className="py-3 px-2 border border-black">
              Fully deleted
              <br />
              Client can't use it to install ScaleOps
            </td>
            <td className="py-3 px-2 border border-black"></td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
