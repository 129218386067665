import Tab from "./Tab";
import { TabType } from "./utils";

interface Props {
  selectedTab: TabType;
  setSelectedTab: (tab: TabType) => void;
}

const TabsContainer = ({ selectedTab, setSelectedTab }: Props) => {
  return (
    <div className="flex items-center gap-2 h-[20px]">
      <Tab
        label={TabType.INSTALL}
        isSelected={selectedTab === TabType.INSTALL}
        onClick={() => setSelectedTab(TabType.INSTALL)}
      />
      <div className="h-[80%] w-[1px] bg-text-darkGray" />
      <Tab
        label={TabType.UPGRADE}
        isSelected={selectedTab === TabType.UPGRADE}
        onClick={() => setSelectedTab(TabType.UPGRADE)}
      />
    </div>
  );
};

export default TabsContainer;
