import { Typography } from "@mui/material";
import clsx from "clsx";
import CompassIcon from "../../icons/CompassIcon";
import { containerClassName } from "./utils";

const ICON_SIZE = "3.625rem";

const DiscoverBanner = () => {
  return (
    <div
      className={clsx(
        containerClassName,
        "bg-white border border-1 border-black",
      )}
    >
      <CompassIcon
        width={ICON_SIZE}
        height={ICON_SIZE}
        className="max-[1298px]:hidden"
      />
      <div>
        <Typography
          variant="h6"
          fontWeight={700}
          className="text-guideline-darkPurple"
        >
          Discover your potentials starting in Read-Only!
        </Typography>
        <Typography variant="body2">
          ScaleOps starts in <b>Read-Only mode</b>. Once you are ready{" "}
          <b>start automating your workloads!</b>
        </Typography>
      </div>
    </div>
  );
};

export default DiscoverBanner;
