/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getClientByHashName =
  /* GraphQL */ `query GetClientByHashName($name: String, $hash: String, $token: String) {
  getClientByHashName(name: $name, hash: $hash, token: $token) {
    id
    name
    githubToken
    scaleOpsToken
    isScaleOpsDev
    useScaleOpsToken
    url
    metricsVerbosity
    isTrialExpired
    isDeleted
    status
    firstInstallDate
    freeTrialStartDate
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetClientByHashNameQueryVariables,
    APITypes.GetClientByHashNameQuery
  >;
export const getGithubToken = /* GraphQL */ `query GetGithubToken($id: ID!) {
  getGithubToken(id: $id) {
    id
    token
    inUse
    deleted
    name
    tokenStatus
    tokenPath
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGithubTokenQueryVariables,
  APITypes.GetGithubTokenQuery
>;
export const listGithubTokens = /* GraphQL */ `query ListGithubTokens(
  $filter: ModelGithubTokenFilterInput
  $limit: Int
  $nextToken: String
) {
  listGithubTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      token
      inUse
      deleted
      name
      tokenStatus
      tokenPath
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGithubTokensQueryVariables,
  APITypes.ListGithubTokensQuery
>;
export const syncGithubTokens = /* GraphQL */ `query SyncGithubTokens(
  $filter: ModelGithubTokenFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncGithubTokens(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      token
      inUse
      deleted
      name
      tokenStatus
      tokenPath
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncGithubTokensQueryVariables,
  APITypes.SyncGithubTokensQuery
>;
export const getClient = /* GraphQL */ `query GetClient($id: ID!) {
  getClient(id: $id) {
    id
    name
    githubToken
    scaleOpsToken
    isScaleOpsDev
    useScaleOpsToken
    url
    metricsVerbosity
    isTrialExpired
    isDeleted
    status
    firstInstallDate
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetClientQueryVariables, APITypes.GetClientQuery>;
export const listClients = /* GraphQL */ `query ListClients(
  $filter: ModelClientFilterInput
  $limit: Int
  $nextToken: String
) {
  listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      githubToken
      scaleOpsToken
      isScaleOpsDev
      useScaleOpsToken
      url
      metricsVerbosity
      isTrialExpired
      isDeleted
      status
      firstInstallDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListClientsQueryVariables,
  APITypes.ListClientsQuery
>;
export const syncClients = /* GraphQL */ `query SyncClients(
  $filter: ModelClientFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClients(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      githubToken
      scaleOpsToken
      isScaleOpsDev
      useScaleOpsToken
      url
      metricsVerbosity
      isTrialExpired
      isDeleted
      status
      firstInstallDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncClientsQueryVariables,
  APITypes.SyncClientsQuery
>;
export const byName = /* GraphQL */ `query ByName(
  $name: String!
  $sortDirection: ModelSortDirection
  $filter: ModelClientFilterInput
  $limit: Int
  $nextToken: String
) {
  byName(
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      githubToken
      scaleOpsToken
      isScaleOpsDev
      useScaleOpsToken
      url
      metricsVerbosity
      isTrialExpired
      isDeleted
      status
      firstInstallDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ByNameQueryVariables, APITypes.ByNameQuery>;
export const byGithubToken = /* GraphQL */ `query ByGithubToken(
  $githubToken: String!
  $sortDirection: ModelSortDirection
  $filter: ModelClientFilterInput
  $limit: Int
  $nextToken: String
) {
  byGithubToken(
    githubToken: $githubToken
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      githubToken
      scaleOpsToken
      isScaleOpsDev
      useScaleOpsToken
      url
      metricsVerbosity
      isTrialExpired
      isDeleted
      status
      firstInstallDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ByGithubTokenQueryVariables,
  APITypes.ByGithubTokenQuery
>;
export const byScaleOpsToken = /* GraphQL */ `query ByScaleOpsToken(
  $scaleOpsToken: String!
  $sortDirection: ModelSortDirection
  $filter: ModelClientFilterInput
  $limit: Int
  $nextToken: String
) {
  byScaleOpsToken(
    scaleOpsToken: $scaleOpsToken
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      githubToken
      scaleOpsToken
      isScaleOpsDev
      useScaleOpsToken
      url
      metricsVerbosity
      isTrialExpired
      isDeleted
      status
      firstInstallDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ByScaleOpsTokenQueryVariables,
  APITypes.ByScaleOpsTokenQuery
>;
export const byUrl = /* GraphQL */ `query ByUrl(
  $url: String!
  $sortDirection: ModelSortDirection
  $filter: ModelClientFilterInput
  $limit: Int
  $nextToken: String
) {
  byUrl(
    url: $url
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      githubToken
      scaleOpsToken
      isScaleOpsDev
      useScaleOpsToken
      url
      metricsVerbosity
      isTrialExpired
      isDeleted
      status
      firstInstallDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ByUrlQueryVariables, APITypes.ByUrlQuery>;
