import { useEffect, useState } from "react";

const useIsTextIsSelectedInAnElement = (
  ref: React.RefObject<HTMLElement>,
): boolean => {
  const [isTextSelected, setIsTextSelected] = useState(false);

  useEffect(() => {
    const listener = () => {
      const selection = window.getSelection();
      if (selection?.rangeCount && selection.toString().length) {
        const range = selection.getRangeAt(0);
        const commonAncestor = range.commonAncestorContainer;
        if (ref.current && ref.current.contains(commonAncestor)) {
          setIsTextSelected(true);
        } else {
          setIsTextSelected(false);
        }
      } else {
        setIsTextSelected(false);
      }
    };

    document.addEventListener("selectionchange", listener);

    return () => {
      document.removeEventListener("selectionchange", listener);
    };
  }, [ref]);

  return isTextSelected;
};

export default useIsTextIsSelectedInAnElement;
