import { IconProps } from "./IconProps";

const ShowIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg
      height={height}
      width={width}
      className={className}
      fill="currentColor"
      viewBox="0 0 20 20"
      {...props}
    >
      <g>
        <path d="M10 16.25C3.45734 16.25 0.213594 10.5463 0.07875 10.3036C-0.02625 10.1147 -0.02625 9.88516 0.07875 9.69625C0.213594 9.45375 3.45734 3.75 10 3.75C16.5427 3.75 19.7864 9.45375 19.9213 9.69641C20.0263 9.88531 20.0263 10.1148 19.9213 10.3038C19.7864 10.5463 16.5427 16.25 10 16.25ZM1.36047 9.99906C2.10391 11.1258 5.02281 15 10 15C14.993 15 17.8989 11.1289 18.6395 10.0009C17.8961 8.87422 14.9772 5 10 5C5.00703 5 2.10109 8.87109 1.36047 9.99906ZM10 13.75C7.93219 13.75 6.25 12.0678 6.25 10C6.25 7.93219 7.93219 6.25 10 6.25C12.0678 6.25 13.75 7.93219 13.75 10C13.75 12.0678 12.0678 13.75 10 13.75ZM10 7.5C8.62156 7.5 7.5 8.62156 7.5 10C7.5 11.3784 8.62156 12.5 10 12.5C11.3784 12.5 12.5 11.3784 12.5 10C12.5 8.62156 11.3784 7.5 10 7.5Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShowIcon;
