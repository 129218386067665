const Terms = () => (
  <div className="text-xs  text-center py-8">
    * Please note that by installing the ScaleOps platform, you are accepting
    our{" "}
    <a
      href="https://scaleops.com/wp-content/uploads/2024/04/ScaleOps-Terms-of-Use.pdf"
      className="text-main-blue"
    >
      Terms of use
    </a>{" "}
    and{" "}
    <a
      href="https://scaleops.com/wp-content/uploads/2024/04/ScaleOps-Privacy-Policy-Jan-1-202312656721.6-1.pdf"
      className="text-main-blue"
    >
      Privacy policy
    </a>
  </div>
);

export default Terms;
