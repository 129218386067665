import { IconProps } from "./IconProps";

const DocsIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg
      height={height}
      width={width}
      className={className}
      fill="currentColor"
      viewBox="0 0 20 20"
      {...props}
    >
      <g>
        <path d="M2.99347 18H3.99353V18.9999C3.99353 19.5523 4.44122 20 4.99359 20H16.9936C17.5458 20 17.9935 19.5523 17.9935 18.9999V3.00003C17.9935 2.44766 17.5458 1.99997 16.9936 1.99997H15.9935V1.00006C15.9935 0.447693 15.5458 0 14.9936 0H5.66025C5.65323 0 5.64682 0.00366211 5.6398 0.00396727C5.6102 0.00595094 5.5809 0.0117493 5.55283 0.0213623C5.54245 0.0250244 5.53253 0.0276184 5.52246 0.0323486C5.48599 0.0483704 5.45273 0.0709534 5.4245 0.0990295L2.09122 3.43231C2.06329 3.46069 2.04071 3.4938 2.02454 3.52997C2.01981 3.54034 2.01721 3.55072 2.01355 3.5614C2.00394 3.58902 1.99814 3.61786 1.99646 3.647C1.99646 3.65433 1.99219 3.66074 1.99219 3.66806V17C1.99219 17.2655 2.09778 17.52 2.28561 17.7075C2.47345 17.8952 2.72812 18.0003 2.99347 18ZM16.9936 2.66663C17.1776 2.66663 17.3268 2.81586 17.3268 3.00003V18.9999C17.3268 19.1841 17.1776 19.3333 16.9936 19.3333H4.99359C4.80942 19.3333 4.66019 19.1841 4.66019 18.9999V18H14.9936C15.5458 18 15.9935 17.5523 15.9935 17V2.66663H16.9936ZM15.3268 1.00006V17C15.3268 17.1841 15.1776 17.3334 14.9936 17.3334H2.99347C2.80945 17.3334 2.66022 17.1841 2.66022 17V3.99994H4.99359C5.54581 3.99994 5.9935 3.55225 5.9935 3.00003V0.666656H14.9936C15.1776 0.666656 15.3268 0.815887 15.3268 1.00006ZM5.32684 1.138V3.00003C5.32684 3.18405 5.17761 3.33328 4.99359 3.33328H3.13156L5.32684 1.138Z" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.32678 5.66666C4.14276 5.66666 3.99353 5.81589 3.99353 6.00006C3.99353 6.18408 4.14276 6.33331 4.32678 6.33331H13.6601C13.8443 6.33331 13.9935 6.18408 13.9935 6.00006C13.9935 5.81589 13.8443 5.66666 13.6601 5.66666H4.32678Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.6601 12.3334H4.32678C4.14276 12.3334 3.99353 12.4826 3.99353 12.6666C3.99353 12.8508 4.14276 13 4.32678 13H13.6601C13.8443 13 13.9935 12.8508 13.9935 12.6666C13.9935 12.4826 13.8443 12.3334 13.6601 12.3334Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.6601 7.33337H11.9936C11.8094 7.33337 11.6602 7.4826 11.6602 7.66663C11.6602 7.8508 11.8094 8.00003 11.9936 8.00003H13.6601C13.8443 8.00003 13.9935 7.8508 13.9935 7.66663C13.9935 7.4826 13.8443 7.33337 13.6601 7.33337Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.6602 7.66663C10.6602 7.4826 10.511 7.33337 10.3268 7.33337H4.32678C4.14276 7.33337 3.99353 7.4826 3.99353 7.66663C3.99353 7.8508 4.14276 8.00003 4.32678 8.00003H10.3268C10.511 8.00003 10.6602 7.8508 10.6602 7.66663Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.32687 13.9999H7.99347C7.80945 13.9999 7.66022 14.1492 7.66022 14.3333C7.66022 14.5174 7.80945 14.6666 7.99347 14.6666H8.32687C8.51089 14.6666 8.66013 14.5174 8.66013 14.3333C8.66013 14.1492 8.51089 13.9999 8.32687 13.9999Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.3269 13.9999H4.32678C4.14276 13.9999 3.99353 14.1492 3.99353 14.3333C3.99353 14.5174 4.14276 14.6666 4.32678 14.6666H6.3269C6.51093 14.6666 6.66016 14.5174 6.66016 14.3333C6.66016 14.1492 6.51093 13.9999 6.3269 13.9999Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.32678 9.6666H5.9935C6.17767 9.6666 6.3269 9.51736 6.3269 9.33334C6.3269 9.14917 6.17767 8.99994 5.9935 8.99994H4.32678C4.14276 8.99994 3.99353 9.14917 3.99353 9.33334C3.99353 9.51736 4.14276 9.6666 4.32678 9.6666Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.6601 8.99994H7.66022C7.47604 8.99994 7.32681 9.14917 7.32681 9.33334C7.32681 9.51736 7.47604 9.6666 7.66022 9.6666H13.6601C13.8443 9.6666 13.9935 9.51736 13.9935 9.33334C13.9935 9.14917 13.8443 8.99994 13.6601 8.99994Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.32678 11.3333H5.32684C5.51102 11.3333 5.66025 11.1841 5.66025 11.0001C5.66025 10.8159 5.51102 10.6667 5.32684 10.6667H4.32678C4.14276 10.6667 3.99353 10.8159 3.99353 11.0001C3.99353 11.1841 4.14276 11.3333 4.32678 11.3333Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.99356 11.3333H10.9935C11.1777 11.3333 11.3269 11.1841 11.3269 11.0001C11.3269 10.8159 11.1777 10.6667 10.9935 10.6667H6.99356C6.80939 10.6667 6.66016 10.8159 6.66016 11.0001C6.66016 11.1841 6.80939 11.3333 6.99356 11.3333Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.6601 10.6667H12.6602C12.476 10.6667 12.3268 10.8159 12.3268 11.0001C12.3268 11.1841 12.476 11.3333 12.6602 11.3333H13.6601C13.8443 11.3333 13.9935 11.1841 13.9935 11.0001C13.9935 10.8159 13.8443 10.6667 13.6601 10.6667Z"
        />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DocsIcon;
