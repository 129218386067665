import { Typography } from "@mui/material";
import clsx from "clsx";

export const HAS_STRINGER_TAB = true;

const getLighterClassName = (isSelected: boolean) =>
  clsx(
    "cursor-pointer",
    isSelected ? "text-black underline" : "text-text-darkGray",
  );

const getStrongerClassName = (isSelected: boolean) =>
  clsx(
    "cursor-pointer px-2 py-0.5 rounded-md border select-none",
    isSelected
      ? "text-black underline bg-primary-lightPurpleBlue border-black hover:bg-guideline-lessDarkPurple"
      : "text-text-lightBlack bg-background-chip border-text-disable hover:text-black hover:border-black",
  );

interface Props {
  label: string;
  isSelected: boolean;
  onClick: () => void;
}

const Tab = ({ label, isSelected, onClick }: Props) => (
  <Typography
    variant="body2"
    className={
      HAS_STRINGER_TAB
        ? getStrongerClassName(isSelected)
        : getLighterClassName(isSelected)
    }
    onClick={onClick}
  >
    {label}
  </Typography>
);

export default Tab;
