export const schema = {
  models: {
    GithubToken: {
      name: "GithubToken",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        token: {
          name: "token",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        inUse: {
          name: "inUse",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        deleted: {
          name: "deleted",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        tokenStatus: {
          name: "tokenStatus",
          isArray: false,
          type: {
            enum: "TokenStatus",
          },
          isRequired: false,
          attributes: [],
        },
        tokenPath: {
          name: "tokenPath",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "GithubTokens",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Client: {
      name: "Client",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        githubToken: {
          name: "githubToken",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        scaleOpsToken: {
          name: "scaleOpsToken",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        isScaleOpsDev: {
          name: "isScaleOpsDev",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        useScaleOpsToken: {
          name: "useScaleOpsToken",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        url: {
          name: "url",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        metricsVerbosity: {
          name: "metricsVerbosity",
          isArray: false,
          type: {
            enum: "MetricsVisability",
          },
          isRequired: false,
          attributes: [],
        },
        isTrialExpired: {
          name: "isTrialExpired",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isDeleted: {
          name: "isDeleted",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: {
            enum: "Status",
          },
          isRequired: false,
          attributes: [],
        },
        firstInstallDate: {
          name: "firstInstallDate",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        salesForceAccountUrl: {
          name: "salesForceAccountUrl",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        accountExecutive: {
          name: "accountExecutive",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        solutionsEngineer: {
          name: "solutionsEngineer",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        accountExecutiveName: {
          name: "accountExecutiveName",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        solutionsEngineerName: {
          name: "solutionsEngineerName",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        companySize: {
          name: "companySize",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        linkedInUrl: {
          name: "linkedInUrl",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Clients",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "byName",
            queryField: "byName",
            fields: ["name"],
          },
        },
        {
          type: "key",
          properties: {
            name: "byGithubToken",
            queryField: "byGithubToken",
            fields: ["githubToken"],
          },
        },
        {
          type: "key",
          properties: {
            name: "byScaleOpsToken",
            queryField: "byScaleOpsToken",
            fields: ["scaleOpsToken"],
          },
        },
        {
          type: "key",
          properties: {
            name: "byUrl",
            queryField: "byUrl",
            fields: ["url"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["get"],
              },
            ],
          },
        },
      ],
    },
  },
  enums: {
    MetricsVisability: {
      name: "MetricsVisability",
      values: ["KEEP", "NONE", "MINIMAL", "POC", "ALL"],
    },
    Status: {
      name: "Status",
      values: ["FreeTrial", "Premium", "Pause", "Terminated"],
    },
    TokenStatus: {
      name: "TokenStatus",
      values: ["ACTIVE", "TO_BE_DELETED", "DELETED"],
    },
  },
  nonModels: {
    ClientByHashNameResponse: {
      name: "ClientByHashNameResponse",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        githubToken: {
          name: "githubToken",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        scaleOpsToken: {
          name: "scaleOpsToken",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        isScaleOpsDev: {
          name: "isScaleOpsDev",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        useScaleOpsToken: {
          name: "useScaleOpsToken",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        url: {
          name: "url",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        metricsVerbosity: {
          name: "metricsVerbosity",
          isArray: false,
          type: {
            enum: "MetricsVisability",
          },
          isRequired: false,
          attributes: [],
        },
        isTrialExpired: {
          name: "isTrialExpired",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isDeleted: {
          name: "isDeleted",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: {
            enum: "Status",
          },
          isRequired: false,
          attributes: [],
        },
        firstInstallDate: {
          name: "firstInstallDate",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        freeTrialStartDate: {
          name: "freeTrialStartDate",
          isArray: false,
          type: "AWSDate",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: true,
          attributes: [],
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: true,
          attributes: [],
        },
        salesForceAccountUrl: {
          name: "salesForceAccountUrl",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        accountExecutive: {
          name: "accountExecutive",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        solutionsEngineer: {
          name: "solutionsEngineer",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        companySize: {
          name: "companySize",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        linkedInUrl: {
          name: "linkedInUrl",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        _version: {
          name: "_version",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        _deleted: {
          name: "_deleted",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        _lastChangedAt: {
          name: "_lastChangedAt",
          isArray: false,
          type: "AWSTimestamp",
          isRequired: true,
          attributes: [],
        },
      },
    },
    GetClientsResponse: {
      name: "GetClientsResponse",
      fields: {
        items: {
          name: "items",
          isArray: true,
          type: {
            nonModel: "ClientByHashNameResponse",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
      },
    },
  },
  codegenVersion: "3.4.4",
  version: "694840fd6e3af663daef2bc6277d48b1",
};
