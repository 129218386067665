import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { AmplifyUser } from "@aws-amplify/ui";
import React, { useEffect } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import GithubTokensTable from "../components/GithubTokensTable";
import awsConfig from "../aws-exports";
import { Amplify, AuthModeStrategyType } from "aws-amplify";
import ClientsTableV2 from "../components/ClientsTableV2";
import Help from "../components/Help";

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: window.location.href.split("?")[0],
    redirectSignOut: window.location.href.split("?")[0],
  },
};

export default function Admin() {
  useEffect(() => {
    Amplify.configure({
      ...updatedAwsConfig,
      DataStore: {
        authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
      },
    });
  }, []);
  return (
    <Authenticator hideSignUp={true}>
      {({ signOut, user }) =>
        user ? (
          <Content user={user} />
        ) : (
          // <main>
          //   <h1>Hello {user.attributes?.email}</h1>
          //   <button onClick={signOut}>Sign out</button>
          // </main>
          <div>Please Login</div>
        )
      }
    </Authenticator>
  );
}

interface ContentProps {
  user: AmplifyUser;
}
function Content(props: ContentProps) {
  const [value, setValue] = React.useState("clients");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Clients" value="clients" />
            <Tab label="Github Tokens" value="tokens" />
            <Tab label="Help" value="help" />
          </TabList>
        </Box>
        <TabPanel value="clients">
          <ClientsTableV2 />
        </TabPanel>
        <TabPanel value="tokens">
          <GithubTokensTable />
        </TabPanel>
        <TabPanel value="help">
          <Help />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
