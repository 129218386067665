import clsx from "clsx";
import CheckIcon from "../icons/CheckIcon";

export enum THEME {
  GREEN = "green",
  PURPLE = "purple",
}

const DEFAULT_PROPS = {
  width: "20px",
  height: "20px",
  cursor: "pointer",
  marginRight: "10px",
  border: "1px solid #ccc",
  borderRadius: "4px",
};

interface Props {
  value: boolean;
  onClick: (value?: boolean) => void;
  disabled?: boolean;
  theme?: THEME;
}

const CustomCheckBox = ({
  value,
  onClick,
  disabled,
  theme = THEME.GREEN,
}: Props) => {
  const unselectedClassName = "bg-white group-hover:bg-border";

  let selectedClassName = "";

  switch (theme) {
    case THEME.GREEN:
      selectedClassName = "bg-main-green group-hover:bg-guideline-lightGreen";
      break;
    case THEME.PURPLE:
      selectedClassName =
        "bg-guideline-darkPurple group-hover:bg-guideline-darkPurple";
      break;
    default:
      break;
  }

  return (
    <div
      className={clsx("relative w-[20px] h-[20px] group", {
        "opacity-50": disabled,
        "cursor-pointer": !disabled,
        "cursor-not-allowed": disabled,
      })}
      onClick={() => {
        !disabled && onClick(!value);
      }}
    >
      <input
        type="checkbox"
        style={{
          appearance: "none",
          WebkitAppearance: "none",
          MozAppearance: "none",
          outline: "none",
          transition: "all 150ms",
          ...DEFAULT_PROPS,
        }}
        className={clsx({
          [selectedClassName]: value,
          [unselectedClassName]: !value,
        })}
        checked={value}
        disabled={disabled}
      />
      {value && (
        <CheckIcon className="text-white rounded-full absolute top-0 right-0" />
      )}
    </div>
  );
};

export default CustomCheckBox;
