import React, { useEffect, useState } from "react";
import { DataStore } from "aws-amplify";
import { GithubToken } from "../models";

const GithubTokenImport = () => {
  const [file, setFile] = useState<Blob | null>(null);
  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    setFile(file);
  };

  useEffect(() => {
    console.log(file);
  }, [file]);

  const saveToken = async (
    token: string,
    tokenName: string,
  ): Promise<GithubToken> => {
    return await DataStore.save(
      new GithubToken({
        token: token,
        name: tokenName,
        deleted: false,
        inUse: false,
      }),
    );
  };
  const saveTokens = (d: { token: string; tokenName: string }[]) => {
    d.forEach(({ token, tokenName }) => {
      saveToken(token, tokenName)
        .then((savedToken) => {
          console.log(savedToken);
        })
        .catch((reason) => console.error(reason));
    });
  };

  const handleUpload = () => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const text = e.target?.result;
      const d: { token: string; tokenName: string }[] = JSON.parse(
        text as string,
      );
      saveTokens(d);
    };

    if (file) {
      reader.readAsText(file);
      setFile(null);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFile} />
      <button onClick={handleUpload}>Upload</button>
    </div>
  );
};

export default GithubTokenImport;
